
import Cookies from "js-cookie";
import { authDataKey } from "./const-keys";

export const scrollToBottomSmoothly = (element: HTMLDivElement, params?: { smooth: boolean }) => {
    const b: ScrollBehavior = ((params?.smooth ?? true) ? 'smooth' : 'instant') as ScrollBehavior
    element.scroll({
        top: element?.scrollHeight,
        left: 0,
        behavior: b,
    });
}

export const shuffle = (array: any[]) => {
    let currentIndex = array.length;

    while (currentIndex != 0) {

        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
}

export const capitalizeFirstLetter = (string: string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const customAlert = (message: any) => {
    if (`${message}`.includes('signal is aborted')) {
        return
    }
    
    alert(message)
}

export const setAuth = (authData: any) => {
    Cookies.set(authDataKey, JSON.stringify(authData))
}

export const calculateBlockWidth = (text: string, className: string, height: number, minWidth = 1) => {
    const tempElement = document.createElement('div');

    tempElement.style.position = 'absolute'
    tempElement.style.visibility = 'hidden'
    tempElement.className = className
    tempElement.style.height = `${height}px`
    tempElement.style.width = `${minWidth}px`
    tempElement.textContent = text

    document.body.appendChild(tempElement)

    let width = minWidth
    while (tempElement?.scrollHeight > tempElement.clientHeight) {
        width++
        tempElement.style.width = `${width}px`
    }

    document.body.removeChild(tempElement)

    return width
}

const formatNumberWithLeadingZeros = (number: number, padding: number) => {
    return number.toString().padStart(padding, '0');
}

export const formatCallTime = (seconds: number, showLeadingMinutesZero = true) => {
    const min = Math.floor(seconds / 60)
    const sec = seconds % 60
    return formatNumberWithLeadingZeros(min, showLeadingMinutesZero ? 2 : 1) + ':' + formatNumberWithLeadingZeros(sec, 2)
}