import { iosAppStoreUrl } from "../../app/config";

const MobileRestrictingComponent = () => {
    return (
        <a href={iosAppStoreUrl} target='_blank'>
            <div className='absolute inset-0 w-full h-full flex items-center justify-center bg-black'>
                <img src='images/mobile-restricting.svg' className='w-full h-full' />
            </div>
        </a>
    )
}

export default MobileRestrictingComponent;