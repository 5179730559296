import { useDispatch, useSelector } from "react-redux"
import { selectIsBotIntroTalking, selectIsBotThinking, selectIsChatShownInVoiceMode, selectIsLipsyncOn, selectIsPTTActive, selectIsToInterrupt, setIsChatShownInVoiceMode, setIsLipsyncOn, setIsPTTActive, setIsToInterrupt, setIsVoiceMode } from "../../app/redux/defaultSlice"
import PTTComponent from "./PTTHandler"
import { useEffect } from "react"

const CallButtonsStack = ({ isLivekitConnection, isMicEnabled, setIsMicEnabled, hangup }: {
    isLivekitConnection: boolean,
    isMicEnabled: boolean,
    setIsMicEnabled: (isMicEnabled: boolean) => void,
    hangup: () => void,
}) => {

    const dispatch = useDispatch()
    const isPTTActive = useSelector(selectIsPTTActive)
    const isChatShownInVoiceMode = useSelector(selectIsChatShownInVoiceMode)
    const isLipsyncOn = useSelector(selectIsLipsyncOn)
    const isBotIntroTalking = useSelector(selectIsBotIntroTalking)

    const isThinking = useSelector(selectIsBotThinking)
    useEffect(() => {
        if (isThinking) {
            dispatch(setIsToInterrupt(true))
        }
    }, [isThinking])

    const isToInterrupt = useSelector(selectIsToInterrupt)

    let actionButtonImg
    let actionButtonSubcaption
    let actionButtonBgColor
    if (isToInterrupt) {
        actionButtonImg = <img width={24} height={24} src='/images/voice-mode-interrupt.svg' />
        actionButtonSubcaption = 'Space to interrupt'
        actionButtonBgColor = 'white'

    } else if (isPTTActive) {
        actionButtonImg = <img width={42} height={20} src='/images/ptt-waves.gif' />
        actionButtonSubcaption = 'Release to send'
        actionButtonBgColor = '#D8EFFF'
    } else {
        actionButtonImg = <img src='/images/voice-mode-mic.svg' />
        actionButtonSubcaption = 'Hold space to talk'
        actionButtonBgColor = '#0094ff'
    }

    return (
        <div
            style={{
                display: 'flex',
                gap: 40,
                alignItems: 'center',
                padding: `12px 12px 34px 12px`,
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
                background: 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.30) 100%)'
            }}>

            {!isLivekitConnection && <>
                <div
                    style={{
                        width: 204,
                        height: 55,
                        borderRadius: 16,
                        background: actionButtonBgColor,
                        border: isToInterrupt ? '1px solid #DADADA' : 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        position: 'relative',
                        pointerEvents: isBotIntroTalking ? 'none' : undefined,
                    }}
                    onMouseDown={() => dispatch(setIsPTTActive(true))}
                    onMouseUp={() => dispatch(setIsPTTActive(false))}
                >
                    {actionButtonImg}

                    <div className='voice-mode__bottom-button-tilte text-center mt-[2px] mb-[6px] absolute -bottom-[30px]'>
                        {actionButtonSubcaption}
                    </div>
                </div>

                <div
                    onClick={() => dispatch(setIsLipsyncOn(!isLipsyncOn))}
                    style={{
                        cursor: 'pointer',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: 52,
                    }}>
                    <img src={`/images/voice-mode-lipsync-${isLipsyncOn ? 'on' : 'off'}.svg`} />

                    <div className='voice-mode__bottom-button-tilte text-center mt-[2px] mb-[6px] absolute -bottom-[47px]'>
                        Animation
                    </div>
                </div>

                <div
                    onClick={() => dispatch(setIsChatShownInVoiceMode(!isChatShownInVoiceMode))}
                    style={{
                        cursor: 'pointer',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: 52,
                    }}>
                    <img src={`/images/voice-mode-chat-${isChatShownInVoiceMode ? 'on' : 'off'}.svg`} />

                    <div className='voice-mode__bottom-button-tilte text-center mt-[2px] mb-[6px] absolute -bottom-[47px]'>
                        Chat
                    </div>
                </div>
            </>}

            {isLivekitConnection && <div
                    onClick={() => setIsMicEnabled(!isMicEnabled)}
                    style={{
                        cursor: 'pointer',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: 52,
                    }}>
                    <img src={`/images/voice-mode-reg-mic-${isMicEnabled ? 'on' : 'off'}.svg`} />

                    <div className='voice-mode__bottom-button-tilte text-center mt-[2px] mb-[6px] absolute -bottom-[28px]'>
                        { isMicEnabled ? 'Mute' : 'Unmute' }
                    </div>
                </div>}

            <div
                style={{
                    cursor: 'pointer'
                }}
                onClick={() => {
                    dispatch(setIsVoiceMode(false))
                    hangup()
                }}>
                <img src='/images/voice-mode-hang-up.svg' />
            </div>

            <PTTComponent />
        </div>
    )
}

export default CallButtonsStack