import { useSelector } from 'react-redux';
import { TextareaAutosize } from './TextareaAutosize';
import { useEffect, useRef } from 'react';
import { selectCurrentAvatarConfig, selectIsPTTActive } from '../../app/redux/defaultSlice';

const InputComponent = ({ placeholder, value, onChange, onEnter, onHeightChange, onSuggestedTopics }: {
    placeholder: string
    value: string,
    onChange: (newValue: string) => void,
    onEnter: () => void,
    onHeightChange: (h: number) => void,
    onSuggestedTopics: () => void
}) => {
    const textAreaRef = useRef(null)
    const isPTTActive = useSelector(selectIsPTTActive)

    const avatarConfig = useSelector(selectCurrentAvatarConfig)

    useEffect(() => {
        const spaceFixedValue = value.trim()
        onChange(spaceFixedValue)
    }, [isPTTActive])

    return (
        <div className='w-full'>
            <div
                className='input-container relative'>
                <TextareaAutosize
                    className='h-min-[52px] w-full pl-[24px] pr-[88px] pt-[13px] pb-[14px] focus:outline-none'
                    ref={textAreaRef}
                    autoFocus
                    placeholder={placeholder}
                    onKeyDownCapture={(event: any) => {
                        if (event.key === 'Enter') {
                            onEnter()
                            event.preventDefault()
                        }
                    }}
                    style={{
                        borderRadius: 16,
                        borderWidth: 1,
                        borderColor: '#d1d5dc',
                    }}
                    value={value}
                    onChange={(event: any) => {
                        // don't allow multiple spaces in a row for the sake of PTT
                        let newValue: string = event.target.value
                        if (newValue.endsWith('  ')) {
                            newValue = newValue.substring(0, newValue.length - 1)
                        }
                        onChange(newValue)
                    }}
                    onResize={(event: any) => {
                        onHeightChange((event.target as any).offsetHeight)
                    }}
                />

                <button
                    onClick={() => {
                        onEnter();
                        (textAreaRef.current as any).focus()
                    }}
                    style={{
                        position: 'absolute',
                        right: 14,
                        bottom: 17,
                        width: 32,
                        height: 32,
                        borderRadius: 8,
                    }}>
                    <div>
                        {arrowUp(value.length ? 'white' : 'rgba(174, 180, 191, 1)',
                            value.length > 0 ? '#0094FF' : 'rgba(231, 233, 237, 1)')}
                    </div>
                </button>

                <button
                    className='suggested-topics'
                    onClick={() => {
                        onSuggestedTopics()
                    }}
                    style={{
                        position: 'absolute',
                        right: 50,
                        bottom: 17,
                        width: 32,
                        height: 32,
                    }}>
                    <div>
                        <img src='/images/st-bubble.svg' />
                    </div>
                </button>

                <div className='absolute h-10 -bottom-[45px] left-0 right-0 opacity-80' style={{ fontSize: 12 }}>
                    <div className='relative text-center'>
                        This is an AI avatar of {avatarConfig?.display_name}. Check for accuracy.
                    </div>
                </div>

            </div>

        </div>
    )
}

export const defaultInputHeight = 52    // check if check padding and so on

const arrowUp = (color: string, fill: string) => <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" fill={fill} />
    <path d="M16 23V9" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 16L16 9L23 16" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>


export default InputComponent