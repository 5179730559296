import ChatComponent, { chatFullWidth, chatSideGap, chatWidth } from "./ChatComponent"
import ExhumanComponent, { defaultExhumanWidth, exhumanInVoiceModeWidthSmall } from "./ExhumanComponent"
import './css/ChatWithExhumanComponent.css'
import ProfileWidget from "./ProfileWidget"
import HeaderComponent, { headerHeight } from "./HeaderComponent"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentAvatarConfig, selectIsChatShownInVoiceMode, selectIsVoiceMode, setIsVoiceMode } from "../../app/redux/defaultSlice"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import CallButtonsStack from "./CallButtonsComponent"
import { StartCallButton } from "../Supporting/ButtonsComponents"
import { LiveKitRoom, RoomAudioRenderer, useLocalParticipant } from "@livekit/components-react"
import { DisconnectReason, MediaDeviceFailure } from "livekit-client"
import { connectLivekit, endCall, startCall } from "../../app/api"
import { customAlert } from "../../app/utils"

const gap = 25

const ChatGeneralComponent = () => {
    const dispatch = useDispatch()
    const currentAvatar = useSelector(selectCurrentAvatarConfig)
    const navigate = useNavigate()

    useEffect(() => {
        if (!currentAvatar) {
            navigate('/')
        }
    }, [currentAvatar])

    const isVoiceMode = useSelector(selectIsVoiceMode)
    const isChatShownInVoiceMode = useSelector(selectIsChatShownInVoiceMode)

    let exhWidth
    let isMinimalMargin = false
    let contentWidth = undefined
    if (isVoiceMode) {
        if (isChatShownInVoiceMode) {
            exhWidth = exhumanInVoiceModeWidthSmall
            contentWidth = exhumanInVoiceModeWidthSmall + gap + chatFullWidth

        } else {
            exhWidth = '100%'
            isMinimalMargin = true
        }

    } else {
        contentWidth = defaultExhumanWidth + gap + chatFullWidth
        exhWidth = defaultExhumanWidth
    }

    const [isConnecting, setIsConnecting] = useState(false)
    const [isMicEnabled, setIsMicEnabled] = useState(true)
    const [livekitConnectionDetails, updateLivekitConnectionDetails] = useState<LivekitConnectionDetails | undefined>(undefined)
    const onConnectLivekitButtonClicked = useCallback(async () => {
        if (!currentAvatar) {
            return
        }

        setIsConnecting(true)

        try {
            const response = await startCall(currentAvatar.name)
            const connectionDetailsData = await response.json()
            updateLivekitConnectionDetails(connectionDetailsData)

        } catch (err) {
            alert(`${err}`)

        } finally {
            setIsConnecting(false)
        }

    }, [currentAvatar])

    useEffect(() => {
        if (livekitConnectionDetails) {
            dispatch(setIsVoiceMode(true))
        } else {
            dispatch(setIsVoiceMode(false))
        }
    }, [livekitConnectionDetails])

    useEffect(() => {
        return () => {
            if (isVoiceMode) {
                console.log('isVoiceMode offing??', isVoiceMode)

                if (!livekitConnectionDetails || !currentAvatar) {
                    return
                }
                endCall(currentAvatar.name, livekitConnectionDetails.room_name)
                    .catch(err => {
                        console.error(`Error ending call: ${err}`)
                    })
                    .finally(() => {
                        updateLivekitConnectionDetails(undefined)
                    })
            }
        }
    }, [isVoiceMode])

    return <div className='h-full' style={{ background: isVoiceMode ? 'linear-gradient(180deg, #fff 0%, #0094FF40 100%)' : 'linear-gradient(180deg, #F9FAFB 0%, #E3E7EC 100%)' }}>

        <HeaderComponent />

        <LiveKitRoom
            token={livekitConnectionDetails?.participant_token}
            serverUrl={livekitConnectionDetails?.server_url}
            connect={livekitConnectionDetails !== undefined}
            audio={true}
            video={false}
            onMediaDeviceFailure={onDeviceLivekitFailure}
            onDisconnected={(reason: DisconnectReason | undefined) => {
                //? reason///
                console.log('livekit onDisconnected reason', reason)
                updateLivekitConnectionDetails(undefined);
            }}
            className="grid grid-rows-[2fr_1fr] items-center"
        >
            <RoomAudioRenderer />
            <MicMuteHandler isMicEnabled={isMicEnabled} />

        </LiveKitRoom>

        <div
            className='main-block'
            style={{
                display: 'flex',
                width: contentWidth,
                minWidth: 500,
                marginLeft: isMinimalMargin ? 0 : `max(50% - ${contentWidth! / 2}px, 25px)`,
                height: `calc(100% - ${headerHeight}px)`,
            }}>

            <div
                style={{
                    height: '100%',
                    paddingTop: 25,
                    marginRight: isVoiceMode ? 0 : gap,
                    width: exhWidth,
                    position: 'relative'
                }}>
                <ExhumanComponent
                    startLivekitConnection={onConnectLivekitButtonClicked}
                    isConnectingLivekit={isConnecting}
                    isConnectedLivekit={livekitConnectionDetails != undefined}
                />
            </div>

            <div
                style={{
                    width: chatFullWidth,
                    height: '100%',
                    marginBottom: 25,
                    display: (isVoiceMode && !isChatShownInVoiceMode) ? 'none' : undefined
                }}>
                <ChatComponent />
            </div>

            <div className='flex justify-center absolute bottom-0 left-0 right-0'>
                {isVoiceMode &&
                    <CallButtonsStack
                        isLivekitConnection={livekitConnectionDetails != undefined}
                        isMicEnabled={isMicEnabled}
                        setIsMicEnabled={setIsMicEnabled}
                        hangup={() => dispatch(setIsVoiceMode(false))} />}
            </div>
        </div>
    </div>
}

const MicMuteHandler = ({ isMicEnabled }: { isMicEnabled: boolean }) => {
    const { localParticipant } = useLocalParticipant()
    useEffect(() => {
        if (localParticipant) {
            localParticipant.trackPublications.forEach(trackPublication => {
                if (isMicEnabled) {
                    trackPublication.audioTrack?.unmute()
                } else {
                    trackPublication.audioTrack?.mute()
                }
            })
        }
    }, [localParticipant, isMicEnabled])

    return null
}

const onDeviceLivekitFailure = (error?: MediaDeviceFailure) => {
    console.error(error)
    alert("Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab")
}

type LivekitConnectionDetails = {
    server_url: string;
    room_name: string;
    participant_name: string;
    participant_token: string;
}

export default ChatGeneralComponent